import { rulesNumAuto } from '@/core'

export const ruleFormEditWhitelist = {
  reason: [
    {
      required: true,
      message: 'Укажите причину добавления',
      trigger: 'submit',
    },
  ],
  white_by: [
    {
      required: true,
      message: 'Укажите своё ФИО',
      trigger: 'submit',
    },
  ],
  ...rulesNumAuto,
}
